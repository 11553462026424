/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { Container } from 'src/components/common/Container'
import { Squidex_Howwework } from 'src/types/gatsby-types'
import { WorkflowItem } from 'src/components/Workflow/WorkflowItem'
import { headingSecondary } from 'src/components/common/Typography'
import { motion } from 'framer-motion'
import { mq } from 'src/utils/mq'

const wrapper = css`
  ${mq.md} {
    position: relative;
  }
  ${mq.lg} {
    padding: 0 45px;
  }
`

const heading = css`
  ${headingSecondary}
  text-align: center;
  padding: 110px 0 88px;
  margin: 0;

  ${mq.md} {
    padding: 75px 0 64px;
  }
`

type Data = {
  squidex: {
    queryHowweworkContents: Squidex_Howwework[]
  }
}

export const Workflow: React.FC = () => {
  const response: Data = useStaticQuery(
    graphql`
      query {
        squidex {
          queryHowweworkContents {
            flatData {
              blocks {
                id
                flatData {
                  description
                  illustration {
                    ...IllustrationImage
                  }
                  subtitle
                  title
                  contentCssClass
                }
              }
            }
          }
        }
      }
    `,
  )

  const data = response?.squidex?.queryHowweworkContents?.[0]

  if (!data) {
    throw new Error('No data for Workflow blocks')
  }

  return (
    <div css={{ overflow: 'hidden' }} id="get-started">
      <Container css={wrapper}>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.4 }}
        >
          <h2 css={heading}>Workflow</h2>
        </motion.div>
        {data.flatData.blocks?.map((item) => (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4 }}
            key={item.id}
          >
            <WorkflowItem data={item} />
          </motion.div>
        ))}
      </Container>
    </div>
  )
}
