import { HowWeWorkSplash } from 'src/components/HowWeWorkSplash/HowWeWorkSplash'
import { InterestedInServicesForm } from 'src/components/InterestedInServicesForm/InterestedInServicesForm'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'
import { Workflow } from 'src/components/Workflow/Workflow'

const HowWeWork: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="How we work | RabbitPeepers">
      <HowWeWorkSplash />
      <Workflow />
      <InterestedInServicesForm />
    </Layout>
  )
}

export default HowWeWork
