/** @jsx jsx */

import { graphql, useStaticQuery } from 'gatsby'

import { ButtonLink } from 'src/components/Button/Button'
import { Splash } from 'src/components/Splash/Splash'
import SplashImageSVG from 'src/assets/how-we-work.svg'
import { Squidex_Howwework } from 'src/types/gatsby-types'
import { jsx } from '@emotion/react'
import { useScrollToId } from 'src/components/common/useScrollToId'

type Data = {
  squidex: {
    queryHowweworkContents: Squidex_Howwework[]
  }
}

export const HowWeWorkSplash: React.FC = () => {
  const handleScroll = useScrollToId<HTMLAnchorElement>('get-started')
  const response: Data = useStaticQuery(
    graphql`
      query {
        squidex {
          queryHowweworkContents {
            flatData {
              subtitle
              title
            }
          }
        }
      }
    `,
  )

  const data = response?.squidex?.queryHowweworkContents?.[0]

  if (!data) {
    throw new Error('No data for how we work splash screen')
  }

  return (
    <Splash
      title={data.flatData.title || ''}
      subtitle={data.flatData.subtitle || ''}
      image={SplashImageSVG}
      button={(
        <ButtonLink to="#get-started" variant="contained" size="large" onClick={handleScroll}>
          Get started
        </ButtonLink>
      )}
    />
  )
}
